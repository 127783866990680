import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/effect-cards';

const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
};

const ProductItem = () => {

    const { t } = useTranslation("home");

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 1,
    });

    const phoneImages1 = [
        require('../../assets/capturas/v4/entrenamiento-habilidad.png'),
        require('../../assets/capturas/v4/entrenamiento.png'),
        require('../../assets/capturas/v4/entrenamiento-fisico.png'),
    ];

    const phoneImages4 = [
        require('../../assets/capturas/v4/protips-recetas.png'),
        require('../../assets/capturas/v4/protips-motivacion.png'),
        require('../../assets/capturas/v4/protips-consejos.png'),
    ];

    const phoneImages5 = [
        require('../../assets/capturas/v4/planes-1.png'),
        require('../../assets/capturas/v4/planes.png'),
        require('../../assets/capturas/v4/planes-2.png'),
    ];

    const phoneImages8 = [
        require('../../assets/capturas/v4/estadisticas-2.png'),
        require('../../assets/capturas/v4/estadisticas.png'),
    ];

    return (
        <div className="p-0">
            <motion.div
                className="flex flex-col items-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                ref={ref}
                inView={inView}
            >
                <div style={{ backgroundColor: "#33333313", flex: 1, width: '100%' }}>
                    <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[60%] m-auto">

                        <Swiper
                            effect="cards"
                            grabCursor={true}
                            centeredSlides={true}
                            initialSlide={1}
                            modules={[EffectCards]}
                            className="w-[60vw] h-auto md:w-[20vw]" // Tamaño del carrusel
                            cardsEffect={{
                                slideShadows: false,
                                perSlideOffset: 15,
                            }}
                        >
                            {phoneImages1.map((url, index) => (
                                <SwiperSlide key={index}>
                                    <img
                                        src={url}
                                        alt={`Phone ${index + 1}`}
                                        className="w-[60vw] h-auto md:w-[20vw]"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                            <h2 className="text-2xl font-bold mb-4">{t("section_1_title").toUpperCase()}</h2>
                            <p>{t("section_1_description")}</p>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, width: '100%', height: 100 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ width: '100%', height: 100 }}
                        preserveAspectRatio="none"
                    >
                        <path fill="#33333313" fillOpacity="1" d="M0,224L120,202.7C240,181,480,139,720,133.3C960,128,1200,160,1320,176L1440,192L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                    </svg>
                </div>

            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[60%] m-auto">
                    <img
                        src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasAppV4.1.0%2Fpng%2Fdetalle-entenameniento.png?alt=media&token=340f1909-f1bb-4df8-8db9-83fc5378bdb5"}
                        alt={t("section_2_title")}
                        className="w-[60vw] h-auto md:w-[25vw]"
                    />

                    <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                        <h2 className="text-2xl font-bold mb-4">{t("section_2_title").toUpperCase()}</h2>
                        <p>{t("section_2_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col items-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                ref={ref}
                inView={inView}
            >
                <div style={{ flex: 1, width: '100%', height: 100 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ width: '100%', height: 100 }}
                        preserveAspectRatio="none"
                    >
                        <path fill="#33333313" fill-opacity="1" d="M0,224L120,229.3C240,235,480,245,720,213.3C960,181,1200,107,1320,69.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                </div>

                <div style={{ backgroundColor: "#33333313", flex: 1, width: '100%' }}>
                    <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[60%] m-auto">
                        <Swiper
                            effect="cards"
                            grabCursor={true}
                            centeredSlides={true}
                            initialSlide={1}
                            modules={[EffectCards]}
                            className="w-[60vw] h-auto md:w-[20vw]" // Tamaño del carrusel
                            cardsEffect={{
                                slideShadows: false,
                                perSlideOffset: 15,
                            }}
                        >
                            {phoneImages5.map((url, index) => (
                                <SwiperSlide key={index}>
                                    <img
                                        src={url}
                                        alt={`Phone ${index + 1}`}
                                        className="w-[60vw] h-auto md:w-[20vw]"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                            <h2 className="text-2xl font-bold mb-4">{t("section_5_title").toUpperCase()}</h2>
                            <p>{t("section_5_description")}</p>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, width: '100%', height: 100 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ width: '100%', height: 100 }}
                        preserveAspectRatio="none"
                    >
                        <path fill="#33333313" fill-opacity="1" d="M0,160L120,144C240,128,480,96,720,74.7C960,53,1200,43,1320,37.3L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                    </svg>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[60%] m-auto">
                    <Swiper
                        effect="cards"
                        grabCursor={true}
                        centeredSlides={true}
                        initialSlide={1}
                        modules={[EffectCards]}
                        className="w-[60vw] h-auto md:w-[20vw]" // Tamaño del carrusel
                        cardsEffect={{
                            slideShadows: false,
                            perSlideOffset: 15,
                        }}
                    >
                        {phoneImages4.map((url, index) => (
                            <SwiperSlide key={index}>
                                <img
                                    src={url}
                                    alt={`Phone ${index + 1}`}
                                    className="w-[60vw] h-auto md:w-[20vw]"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                        <h2 className="text-2xl font-bold mb-4">{t("section_4_title").toUpperCase()}</h2>
                        <p>{t("section_4_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col items-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                ref={ref}
                inView={inView}
            >
                <div style={{ flex: 1, width: '100%', height: 100 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ width: '100%', height: 100 }}
                        preserveAspectRatio="none"
                    >
                        <path fill="#33333313" fill-opacity="1" d="M0,160L120,160C240,160,480,160,720,144C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                </div>

                <div style={{ backgroundColor: "#33333313", flex: 1, width: '100%' }}>
                    <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[60%] m-auto">
                        <img
                            src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasAppV4.1.0%2Fpng%2Fidioma.png?alt=media&token=863d0b8a-8e7b-4500-9506-d62ab1c74d3b"}
                            alt={t("section_5_title")}
                            className="w-[60vw] h-auto md:w-[20vw]"
                        />

                        <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                            <h2 className="text-2xl font-bold mb-4">{t("section_6_title").toUpperCase()}</h2>
                            <p>{t("section_6_description")}</p>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, width: '100%', height: 100 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ width: '100%', height: 100 }}
                        preserveAspectRatio="none"
                    >
                        <path fill="#33333313" fill-opacity="1" d="M0,96L120,117.3C240,139,480,181,720,192C960,203,1200,181,1320,170.7L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                    </svg>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                style={{ backgroundColor: "#fff" }}
                ref={ref}
                inView={inView}
            >
                <div className="flex flex-col md:flex-row items-center md:flex-row py-8 w-[60%] m-auto">
                    <img
                        src={"https://firebasestorage.googleapis.com/v0/b/deportsenapp.appspot.com/o/web%2FpaginaMuestraApp%2FcapturasAppV4.1.0%2Fpng%2Fcarta-de-jugador.png?alt=media&token=2759863c-abcc-4c7a-846f-f0a0c406ca0e"}
                        alt={t("section_6_title")}
                        className="w-[60vw] h-auto md:w-[20vw]"
                    />

                    <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                        <h2 className="text-2xl font-bold mb-4">{t("section_7_title").toUpperCase()}</h2>
                        <p>{t("section_7_description")}</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                className="flex flex-col items-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.6 }}
                variants={sectionVariants}
                ref={ref}
                inView={inView}
            >

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#33333313" fill-opacity="1" d="M0,224L60,213.3C120,203,240,181,360,181.3C480,181,600,203,720,186.7C840,171,960,117,1080,90.7C1200,64,1320,64,1380,64L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                </svg>

                <div style={{ backgroundColor: "#33333313", flex: 1, width: '100%' }}>
                    <div className="flex flex-col md:flex-row items-center md:flex-row-reverse py-8 w-[60%] m-auto">
                        <Swiper
                            effect="cards"
                            grabCursor={true}
                            centeredSlides={true}
                            initialSlide={1}
                            modules={[EffectCards]}
                            className="w-[60vw] h-auto md:w-[20vw]" // Tamaño del carrusel
                            cardsEffect={{
                                slideShadows: false,
                                perSlideOffset: 15,
                            }}
                        >
                            {phoneImages8.map((url, index) => (
                                <SwiperSlide key={index}>
                                    <img
                                        src={url}
                                        alt={`Phone ${index + 1}`}
                                        className="w-[60vw] h-auto md:w-[20vw]"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className="md:mx-8 text-center md:text-left mt-10 p-4">
                            <h2 className="text-2xl font-bold mb-4">{t("section_8_title").toUpperCase()}</h2>
                            <p>{t("section_8_description")}</p>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default ProductItem;
