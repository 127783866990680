import React, { useState } from 'react';
import { Button, CircularProgress, Alert } from '@mui/material';
import { TextField } from '@mui/material';

const FeedbackSection = ({ t }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [improvementFeedback, setImprovementFeedback] = useState('');
    const [positiveFeedback, setPositiveFeedback] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();

        setIsSubmitting(true);

        try {
            await fetch(process.env.REACT_APP_URL_FEEDBACK_GENERAL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_FEEDBACK}`,
                },
                body: JSON.stringify({
                    user: userEmail,
                    data: {
                        improvementFeedback: improvementFeedback,
                        positiveFeedback: positiveFeedback,
                    }
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {
                        setIsVerificationSent(true);
                        setError('');
                    };
                })
                .catch((error) => {
                    console.log(error);
                    setError(t('deleteAccount:error_request'));
                });
        } catch (error) {
            setError(t('deleteAccount:error_request'));
        };

        resetForm();
    };

    const resetForm = () => {
        setImprovementFeedback("");
        setPositiveFeedback("");
        setUserEmail("");
        setIsSubmitting(false);
    };

    return (
        <div className="flex items-start justify-center min-h-screen p-4">
            {!isVerificationSent ? (
                <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-xl">
                    <h1 className="text-2xl font-bold mb-4 text-center">{t("text_share_opinion")}</h1>
                    <p className="mb-4">{t("text_feedback_description")}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-2">{t("text_improvement_question")}</label>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                placeholder={t("text_improvement_placeholder")}
                                rows={3}
                                value={improvementFeedback}
                                onChange={(e) => setImprovementFeedback(e.target.value)}
                                InputLabelProps={{ style: { whiteSpace: 'normal' } }}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-2">{t("text_positive_question")}</label>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                placeholder={t("text_positive_placeholder")}
                                rows={3}
                                value={positiveFeedback}
                                onChange={(e) => setPositiveFeedback(e.target.value)}
                                InputLabelProps={{ style: { whiteSpace: 'normal' } }}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-2">{t("text_associated_email")}</label>
                            <TextField
                                fullWidth
                                label={t("text_email_label")}
                                multiline
                                variant="outlined"
                                type="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value.toLocaleLowerCase().trim())}
                                className="mb-4"
                                required
                            />
                        </div>

                        {error && <Alert severity="error" className="mb-5">{error}</Alert>}

                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress size={24} /> : t("text_submit_feedback")}
                        </Button>
                    </form>
                </div>
            ) : (
                <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-xl">
                    <h1 className="text-2xl font-bold mb-4 text-center">{t("text_your_opinion_counts")}</h1>
                    <p className="mb-4">{t("text_google_play_rating")}</p>

                    {error && <Alert severity="error" className="mb-5">{error}</Alert>}

                    <Button type="button" variant="contained" color="primary" fullWidth disabled={isSubmitting}
                        onClick={() => window.open("https://play.google.com/store/apps/details?id=com.hernan.dev.Deportsen", "_blank")}>
                        {isSubmitting ? <CircularProgress size={24} /> : t("text_leave_rating")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FeedbackSection;
