import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: true,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Ruta a los archivos JSON en public/locales
        },
        ns: ['home', 'faq', 'deleteAccount', 'header', 'footer', 'about', 'feedback'],
        defaultNS: 'home',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
