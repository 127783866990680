import React, { useState } from 'react';
import { Button, CircularProgress, Alert } from '@mui/material';
import DeleteAccountReason from './DeleteAccountReason';
import DeleteAccountComments from './DeleteAccountComments';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';
import VerificationCodeForm from './VerificationCodeForm';

const DeleteAccountForm = ({ t }) => {

    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [comments, setComments] = useState('');
    const [specificProblem, setSpecificProblem] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [alertDeleteConfirmation, setAlertDeleteConfirmation] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [requestDate] = useState(new Date().toISOString());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [isCodeVerified, setIsCodeVerified] = useState(null);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [maxTried, setMaxTried] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!deleteConfirmation) {
            setAlertDeleteConfirmation(true);
            return;
        } else {
            setAlertDeleteConfirmation(false);
        }

        if (!validateEmail(userEmail)) {
            setEmailError(t('error_email'));
            return;
        }

        setEmailError('');
        setIsSubmitting(true);

        try {
            await fetch(process.env.REACT_APP_URL_SEND_EMAIL_CODING, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    reason,
                    otherReason,
                    comments,
                    specificProblem,
                    additionalComments,
                    deleteConfirmation,
                    userEmail,
                    requestDate
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {
                        setIsVerificationSent(true);
                        setError('');
                    } else {
                        setError(t('error_email_not_registered'));
                    }
                })
                .catch((error) => {
                    setError(t('error_request'));
                });
        } catch (error) {
            setError(t('error_request'));
        }
        setIsSubmitting(false);
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await fetch(process.env.REACT_APP_URL_CHECK_CODE_VERIFICATION, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: userEmail,
                    code: verificationCode,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {

                        if (data.success) {
                            setIsCodeVerified(true);
                            setTimeout(() => resetFormFields(), 5000);
                        } else {
                            if (data.maxTried) {
                                setIsCodeVerified(false);
                                setMaxTried(true);
                                setTimeout(() => resetFormFields(), 2000);
                            } else {
                                setIsCodeVerified(false);
                            }
                        }
                    } else {
                        setError(t('error_request'));
                        setTimeout(() => resetFormFields(), 2000);
                    }
                })
                .catch((error) => {
                    setError(t('error_request'));
                    setTimeout(() => resetFormFields(), 2000);
                });
        } catch (error) {
            setError(t('error_request'));
            setTimeout(() => resetFormFields(), 2000);
        }

        setIsSubmitting(false);
    };

    const resetFormFields = () => {
        setReason('');
        setOtherReason('');
        setComments('');
        setSpecificProblem('');
        setAdditionalComments('');
        setDeleteConfirmation(false);
        setUserEmail('');
        setVerificationCode('');
        setEmailError('');
        setIsVerificationSent(false);
        setError('');
        setIsSubmitting(false);
        setIsCodeVerified(null);
        setMaxTried(false);
        setAlertDeleteConfirmation(false);
    }

    return (
        <div className="flex items-start justify-center min-h-screen p-4">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-xl">
                <h1 className="text-2xl font-bold mb-4 text-center">{t('delete_account_title')}</h1>

                {!isVerificationSent &&
                    <>
                        <p className="mb-4">{t('delete_account_description')}</p>
                        <form onSubmit={handleSubmit}>
                            <DeleteAccountReason reason={reason} setReason={setReason} otherReason={otherReason} setOtherReason={setOtherReason} t={t} />
                            <DeleteAccountComments comments={comments} setComments={setComments} specificProblem={specificProblem} setSpecificProblem={setSpecificProblem} additionalComments={additionalComments} setAdditionalComments={setAdditionalComments} t={t} />
                            <DeleteAccountConfirmation deleteConfirmation={deleteConfirmation} setDeleteConfirmation={setDeleteConfirmation} userEmail={userEmail} setUserEmail={setUserEmail} alertDeleteConfirmation={alertDeleteConfirmation} isSubmitting={isSubmitting} t={t} />

                            {emailError && <p className="text-red-500 text-sm mb-5">{emailError}</p>}

                            {error && <Alert severity="error" className="mb-5">{error}</Alert>}

                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress size={24} /> : t('send_request')}
                            </Button>
                        </form>
                    </>
                }

                {isVerificationSent &&
                    <>
                        <p>{t('send_code_verification')} {userEmail}.</p>
                        <p className="mb-4">{t('check_spam')}</p>

                        <VerificationCodeForm userEmail={userEmail} verificationCode={verificationCode} setVerificationCode={setVerificationCode} handleVerification={handleVerification} isCodeVerified={isCodeVerified} maxTried={maxTried} error={error} isSubmitting={isSubmitting} t={t} />
                    </>
                }
            </div>
        </div>
    );
};

export default DeleteAccountForm;
